import classes from "./Card.module.css";

const Card = (props) => {
  return (
    <div className={classes.card} style={{backgroundColor: props.backgroundColor}}>
      {props.children}
    </div>
  );
};
export default Card;
