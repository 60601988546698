import { useEffect } from "react";
import classes from "./Map.module.css";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
const defaultIcon = L.icon({
  iconUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png",
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
});

const Map = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const position = [43.0435484, 10.7382472];
  return (
    <div className={classes["map-container"]}>
      <div className={classes.summary}>
        <h2>Come raggiungerci</h2>
        <p>Ci troviamo a Località Poggio al Turco, 57028 Suvereto LI</p>
      </div>
      <br />
      <a
        href="https://www.google.com/maps/place/43%C2%B002'36.8%22N+10%C2%B044'17.7%22E/@43.0435484,10.7360585,17z/data=!3m1!4b1!4m4!3m3!8m2!3d43.0435484!4d10.7382472?hl=it&entry=ttu"
        target="blank"
      >
        <MapContainer
          center={position}
          zoom={15}
          style={{ width: "400px", height: "400px" }}
          className={classes.map}
        >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <Marker position={position} icon={defaultIcon}>
            <Popup>Noi siamo qui!</Popup>
          </Marker>
        </MapContainer>
      </a>
    </div>
  );
};

export default Map;
