import { Fragment } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import Header from "./Layout/Header";
import HomeCard from "./Layout/Presentation/HomeCard";
import Pagination from "./Layout/Presentation/Pagination";
import "bootstrap/dist/css/bootstrap.min.css";
import NavigationBar from "./UI/NavigationBar";
import ContactForm from "./server/ContactForm";
import Map from "./Layout/Map/Map";
import Footer from "./Layout/Component/Footer";
import ApartmentDetails from "./Layout/Presentation/Apartments/ApartmentDetails";

function App() {
  const Apartments = [
    {
      key: 1,
      name: "Bianca",
      bath: 1,
      kitchen: 1,
      postiLetto: ["1 letto matrimoniale", "1 divano letto", "1 letto singolo"],
      description: `L'appartamento si affaccia su una corte esterna, che offre spazi all'aperto privati e tranquilli per godere di momenti di relax.
      Abbiamo poi una zona giorno spaziosa, cucina attrezzata, che renderà ogni momento trascorso nella casa un'esperienza piacevole.
      Il bagno è dotato di tutti i comfort necessari per garantire il massimo comfort agli ospiti.
      La due camere da letto, accoglienti e ben illuminate, offrono un rifugio tranquillo per riposare e rilassarsi.
      La caratteristica di questo appartamento, è la statua di un piccolo suino che ricorda l allevamento di cento  maiali di cinta.`,
    },
    {
      key: 2,
      name: "Chicca",
      bath: 1,
      kitchen: 1,
      postiLetto: ["1 letto matrimoniale", "1 divano letto"],
      singolo: 0,
      description: `L'appartamento si affaccia su una corte esterna, che offre spazi all'aperto privati e tranquilli per godere di momenti di relax.
      Abbiamo poi una zona giorno spaziosa, cucina attrezzata, che renderà ogni momento trascorso nella casa un'esperienza piacevole.
      Il bagno è dotato di tutti i comfort necessari per garantire il massimo comfort agli ospiti.
      La camera da letto, accogliente e ben illuminata, offre un rifugio tranquillo per riposare e rilassarsi.`,
    },
    {
      key: 3,
      name: "Rula",
      bath: 1,
      kitchen: 1,
      postiLetto: ["1 letto matrimoniale", "1 divano letto"],
      singolo: 0,
      description: `L'appartamento si affaccia su una corte esterna, che offre spazi all'aperto privati e tranquilli per godere di momenti di relax.
      Abbiamo poi una zona giorno spaziosa, cucina attrezzata, che renderà ogni momento trascorso nella casa un'esperienza piacevole.
      Il bagno è dotato di tutti i comfort necessari per garantire il massimo comfort agli ospiti.
      La camera da letto, accogliente e ben illuminata, offre un rifugio tranquillo per riposare e rilassarsi.`,
    },
    {
      key: 4,
      name: "Theo",
      bath: 1,
      kitchen: 1,
      postiLetto: ["1 letto matrimoniale", "1 divano letto"],
      singolo: 0,
      description: `L'appartamento vanta una magnifica terrazza che offre una splendida vista sulla tenuta.
        È caratterizzato da una zona giorno spaziosa, cucina attrezzata, che renderà ogni momento trascorso nella casa un'esperienza piacevole.
        Il bagno è dotato di tutti i comfort necessari per garantire il massimo comfort agli ospiti.
        La camera da letto, accogliente e ben illuminata, offre un rifugio tranquillo per riposare e rilassarsi.`,
    },
  ];
  const HomePage = (
    <Fragment>
      <HomeCard />
      <Pagination apartments={Apartments} />
    </Fragment>
  );

  return (
    <Router>
      <Header />
      <main>
        <Routes>
          <Route exact path="/" element={HomePage} />
          <Route path="/contact" element={<ContactForm />} />
          <Route path="/map" element={<Map />} />
          <Route
            path="/apartment/:name"
            element={<ApartmentDetails apartments={Apartments} />}
          />
        </Routes>
      </main>
      <NavigationBar />
      <Footer />
    </Router>
  );
}

export default App;
