import classes from "./CartTemplate.module.css";

const CardTemplate = (props) => {
  const image = props.image && (
    <img
      src={props.image}
      className="card-img img-fluid img"
      alt={props.imageAlt}
    />
  );

  const title1 = props.title1 && <h2>{props.title1}</h2>;
  const par1 = props.par1 && <p>{props.par1}</p>;

  const title2 = props.title2 && <h2>{props.title2}</h2>;
  const par2 = props.par2 && <p>{props.par2}</p>;

  const button1 = props.button1 && (
    <button className={props.button1Class} onClick={props.button1Clicked}>
      {props.button1Text}
    </button>
  );
  return (
    <div className={classes.cardTemplate}>
      <div className="row">
        <div className="col-sm-6">{image}</div>
        <div className="col-sm-6 mt-5">
          {title1}
          {par1}
          {button1}
          <hr />
          {title2}
          {par2}
        </div>
      </div>
    </div>
  );
};
export default CardTemplate;
