import ApartmentItem from "./ApartmentItem";
const ApartmentList = (props) => {
  const Apartments = props.apartmentList.map((a) => (
    <div className="col-lg-6" key={a.key}>
      <ApartmentItem apartment={a} />
    </div>
  ));
  return (
    <div className="container mt-5">
      <div className="row">
        <div className="col-lg-12">
          <div className="row">{Apartments}</div>
        </div>
      </div>
    </div>
  );
};

export default ApartmentList;
