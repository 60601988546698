import { Fragment, useEffect } from "react";
import { useParams } from "react-router-dom";
import Card from "../../../UI/Card";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
const getImage = (name) => {
  const images = require.context("../../../assets/image/Apartments", true);
  const folder = `./${name.toLowerCase()}`;
  const imageFiles = images.keys().filter((key) => key.startsWith(folder));
  const imageArray = imageFiles.map((file) => images(file));
  return imageArray;
};

const ApartmentDetails = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const name = useParams().name;
  const images = getImage(name).map((img, index) => (
    <div className={`carousel-item ${index === 0 ? "active" : ""}`} key={index}>
      <img src={img} className="d-block w-100" alt="immagine appartamento" />
    </div>
  ));

  const apartment = props.apartments.find((a) => {
    return a.name === name;
  });

  const bath = apartment.bath && (
    <Fragment>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="1em"
        viewBox="0 0 512 512"
        className="mt-2 mb-1"
      >
        <path d="M64 131.9C64 112.1 80.1 96 99.9 96c9.5 0 18.6 3.8 25.4 10.5l16.2 16.2c-21 38.9-17.4 87.5 10.9 123L151 247c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0L345 121c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-1.3 1.3c-35.5-28.3-84.2-31.9-123-10.9L170.5 61.3C151.8 42.5 126.4 32 99.9 32C44.7 32 0 76.7 0 131.9V448c0 17.7 14.3 32 32 32s32-14.3 32-32V131.9zM256 352a32 32 0 1 0 0-64 32 32 0 1 0 0 64zm64 64a32 32 0 1 0 -64 0 32 32 0 1 0 64 0zm0-128a32 32 0 1 0 0-64 32 32 0 1 0 0 64zm64 64a32 32 0 1 0 -64 0 32 32 0 1 0 64 0zm0-128a32 32 0 1 0 0-64 32 32 0 1 0 0 64zm64 64a32 32 0 1 0 -64 0 32 32 0 1 0 64 0zm32-32a32 32 0 1 0 0-64 32 32 0 1 0 0 64z" />
      </svg>
      <p>Bagno</p>
    </Fragment>
  );
  const kitchen = apartment.kitchen && (
    <Fragment>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="1em"
        viewBox="0 0 640 512"
        className="mt-2 mb-1"
      >
        <path d="M293.5 3.8c19.7 17.8 38.2 37 55.5 57.7c7.9-9.9 16.8-20.7 26.5-29.5c5.6-5.1 14.4-5.1 20 0c24.7 22.7 45.6 52.7 60.4 81.1c14.5 28 24.2 58.8 24.2 79C480 280 408.7 352 320 352c-89.7 0-160-72.1-160-159.8c0-26.4 12.7-60.7 32.4-92.6c20-32.4 48.1-66.1 81.4-95.8c2.8-2.5 6.4-3.8 10-3.7c3.5 0 7 1.3 9.8 3.8zM370 273c30-21 38-63 20-96c-2-4-4-8-7-12l-36 42s-58-74-62-79c-30 37-45 58-45 82c0 49 36 78 81 78c18 0 34-5 49-15zM32 288c0-17.7 14.3-32 32-32H96c17.7 0 32 14.3 32 32s-14.3 32-32 32v64H544V320c-17.7 0-32-14.3-32-32s14.3-32 32-32h32c17.7 0 32 14.3 32 32v96c17.7 0 32 14.3 32 32v64c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32V416c0-17.7 14.3-32 32-32V288zM320 480a32 32 0 1 0 0-64 32 32 0 1 0 0 64zm160-32a32 32 0 1 0 -64 0 32 32 0 1 0 64 0zM192 480a32 32 0 1 0 0-64 32 32 0 1 0 0 64z" />
      </svg>
      <p>Cucina</p>
    </Fragment>
  );
  const postiLetto = apartment.postiLetto && (
    <Fragment>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="1em"
        viewBox="0 0 640 512"
        className="mt-2 mb-1"
      >
        <path d="M32 32c17.7 0 32 14.3 32 32V320H288V160c0-17.7 14.3-32 32-32H544c53 0 96 43 96 96V448c0 17.7-14.3 32-32 32s-32-14.3-32-32V416H352 320 64v32c0 17.7-14.3 32-32 32s-32-14.3-32-32V64C0 46.3 14.3 32 32 32zm144 96a80 80 0 1 1 0 160 80 80 0 1 1 0-160z" />
      </svg>
      {apartment.postiLetto.map((p, index) => (
        <p key={index}>{"\n\r" + p}</p>
      ))}
    </Fragment>
  );

  return (
    <Card backgroundColor="#c4e4cb">
      <div className="row">
        <div className="col-sm-6">
          <div id="carouselExample" className="carousel slide">
            <div className="carousel-inner">{images}</div>
            <button
              className="carousel-control-prev"
              type="button"
              data-bs-target="#carouselExample"
              data-bs-slide="prev"
            >
              <span
                className="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span className="visually-hidden">Previous</span>
            </button>
            <button
              className="carousel-control-next"
              type="button"
              data-bs-target="#carouselExample"
              data-bs-slide="next"
            >
              <span
                className="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span className="visually-hidden">Next</span>
            </button>
          </div>
        </div>
        <div className="col-sm-6">
          <h2 className="text-center mt-2">{apartment.name}</h2>
          <p>{apartment.description}</p>
          <hr />
          <h3>Servizi:</h3>
          {bath}
          {kitchen}
          {postiLetto}
        </div>
      </div>
    </Card>
  );
};

export default ApartmentDetails;
