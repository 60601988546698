import classes from "./Pagination.module.css";
import Card from "../../UI/Card";
import FirtsCard from "./Card Presentation/FirstCard";
import SecondCard from "./Card Presentation/SecondCard";
import ApartmentList from "./Apartments/ApartmentList";
import Video from "./Video/Video";
const Pagination = (props) => {
  return (
    <div className={classes.midpage}>
      <div className={classes.cardContainer}>
        <Card backgroundColor="#88d8c7d7">
          <FirtsCard />
        </Card>
        <br />
        <Card backgroundColor="#c4e4cb">
          <SecondCard />
        </Card>
      </div>
      <section id="apartments">
        <div className={classes.divisor}></div>
        <h2>I nostri appartamenti</h2>
        <div className={classes.divisor}></div>
        <ApartmentList apartmentList={props.apartments} />
      </section>
      <section id="video">
        <div className={classes.divisor}></div>
        <Video />
      </section>
    </div>
  );
};
export default Pagination;
