import image from "../../../assets/image/night.jpg";
import CardTemplate from "./CardTemplate";

const goToVideo = () => {
  const section = document.getElementById("video");
  section.scrollIntoView({ behavior: "smooth" });
};
const SecondCard = () => {
  const imageAlt =
    "agriturismo toscano pian di farinello illuminato nella notte";
  const title1 = "Le attività";
  const par1 = `
    Presso l'Agriturismo Pian di Farinello, vi invitiamo a scoprire la
    bellezza della vita di campagna e a riconnettervi con la natura.
    Potrete passeggiare attraverso i nostri boschi incantevoli, ammirare i
    campi rigogliosi e godere di una vista mozzafiato sulle colline
    circostanti. Inoltre, se desiderate esplorare ulteriormente l'area
    circostante, saremo felici di fornirvi consigli sui luoghi da
    visitare, inclusi pittoreschi villaggi locali, sentieri naturalistici
    e attrazioni culturali nelle vicinanze.
  `;
  const title2 = "Fuga dalla quotidianità";
  const par2 = `L'Agriturismo è il luogo ideale per coloro che cercano una fuga
    rilassante e autentica nella campagna. Siamo impegnati a offrirvi
    un'esperienza indimenticabile, fondata su principi di sostenibilità,
    tranquillità e autenticità. Non vediamo l'ora di darvi il benvenuto e
    di condividere con voi la nostra passione per la natura e la vita
    rurale.
  `;
  return (
    <CardTemplate
      image={image}
      imageAlt={imageAlt}
      title1={title1}
      par1={par1}
      title2={title2}
      par2={par2}
      button1={true}
      button1Class="btn btn-primary"
      button1Text="Scopri..."
      button1Clicked={goToVideo}
    />
  );
};
export default SecondCard;
