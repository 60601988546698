import classes from "./HomeCard.module.css";
const HomeCard = () => {
  return (
    <section className={classes.summary}>
      <h2>Benvenuti all'Agriturismo Pian di Farinello</h2>
      <p>
        Ci troviamo in un'oasi di natura incontaminata situata su una proprietà
        di 26 ettari.
      </p>
      <p>
        Immerso in un ambiente incantevole, il nostro agriturismo comprende 10
        ettari di bosco rigoglioso e 16 ettari di campi seminativi certificati
        biologici, garantendo un'esperienza autentica e sostenibile.
      </p>
    </section>
  );
};

export default HomeCard;
