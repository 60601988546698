import classes from "./Video.module.css";
const Video = () => {
  return (
    <div className={classes.video}>
      <iframe
        src="https://player.vimeo.com/video/835482139?h=2d94b4f88c&title=0"
        className={classes.iframe}
        allow="autoplay; picture-in-picture"
      ></iframe>
      <script src="https://player.vimeo.com/api/player.js"></script>
    </div>
  );
};

export default Video;
