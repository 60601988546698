import image from "../../../assets/image/cartel.jpg";
import CardTemplate from "./CardTemplate";
const goToApart = () => {
  const section = document.getElementById("apartments");
  section.scrollIntoView({ behavior: "smooth" });
};
const FirtsCard = () => {
  const imageAlt = "strada agriturismo toscano pian di farinello";
  const title1 = "I nostri alloggi";
  const par1 = `
  All'interno della tenuta, troverete quattro appartamenti esclusivi,
  ciascuno con un proprio nome che riflette il suo carattere unico:
  Theo, Chicca, Bianca e Rula. Ogni appartamento è dotato di aria
  condizionata per garantire il massimo comfort durante il vostro
  soggiorno. Inoltre, siamo fieri di seguire un approccio rispettoso
  verso la natura: l'acqua calda per gli appartamenti viene prodotta
  utilizzando pannelli solari, riducendo così l'impatto ambientale.
  `;
  const title2 = "La fauna";
  const par2 = `Nell'ampia tenuta, potrete incontrare due splendide cavalle di razza
  spagnola che vagano liberamente. Queste magnifiche creature aggiungono
  una nota di magia e bellezza al paesaggio circostante, creando
  un'atmosfera tranquilla e rilassante per tutti gli ospiti.`;
  return (
    <CardTemplate
      image={image}
      imageAlt={imageAlt}
      title1={title1}
      par1={par1}
      title2={title2}
      par2={par2}
      button1={true}
      button1Class="btn btn-primary"
      button1Text="Scopri gli appartamenti"
      button1Clicked={goToApart}
    />
  );
};
export default FirtsCard;
