import classes from "./Footer.module.css";
import logo from "../../assets/image/pfsoftware.png";
const Footer = () => {
  return (
    <footer className={classes.footer}>
      <div className="container">
        <div className="row">
          <div className="col-sm-4">
            <h3>Contatti</h3>
            <p>Indirizzo: Strada Provinciale 19, Suvereto LI 57028</p>
            <h5>Telefono:</h5>
            <p>
              <a href="tel: +39 339 664 9441">+39 339 664 9441</a>
            </p>
            <p>
              <a href="tel: +39 393 167 9562">+39 393 167 9562</a>
            </p>
            <p>Email: piandifarinello.agriturismo@gmail.com</p>
            <p>P. Iva: 01700390535</p>
          </div>
          <div className="col-sm-4">
            <h3>Indicazioni Stradali</h3>
            <p>
              Puoi raggiungerci tramite la strada provinciale 19 Suvereto (LI)
            </p>
            <p>
              <a
                href="https://www.google.com/maps/place/43%C2%B002'36.8%22N+10%C2%B044'17.7%22E/@43.0435484,10.7360585,17z/data=!3m1!4b1!4m4!3m3!8m2!3d43.0435484!4d10.7382472?hl=it&entry=ttu"
                target="blank"
              >
                Apri la mappa
              </a>
            </p>
          </div>
          <div className="col-sm-4">
            <a href="https://www.pfsoftware.it/" target="blank">
              Powered by <img src={logo} alt="pf software logo"></img> PF
              Software
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};
export default Footer;
