import { Fragment } from "react";
import { Link } from "react-router-dom";
import classes from "./Header.module.css";
import header from "../assets/image/header.avif";
import logo from "../assets/image/logo.png";
const Header = () => {
  return (
    <Fragment>
      <header className={classes.header}>
        <Link to="/">
          <img src={logo} alt="agriturismo pian di farinello" />
        </Link>
      </header>
      <div className={classes["main-image"]}>
        <img
          src={header}
          alt="agriturimo pian di farinello in campagna tra le colline toscane"
        />
      </div>
    </Fragment>
  );
};

export default Header;
