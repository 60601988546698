import React, { useState, useRef, useEffect } from "react";
import emailjs from "@emailjs/browser";
const ContactForm = () => {
  const form = useRef();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const handleSubmit = async (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_pry8lrq",
        "template_6a2gwt7",
        form.current,
        "h3eCCb-Ve5KvEgvMh"
      )
      .then(
        (result) => {
          alert("Messaggio inviato con successo!");
        },
        (error) => {
          alert("Si è verificato un errore con l'invio del messaggio!");
        }
      );
    setName("");
    setEmail("");
    setMessage("");
  };

  return (
    <div className="container mt-5">
      <form ref={form} onSubmit={handleSubmit}>
        <legend>Contattaci</legend>
        <div className="form-group">
          <label htmlFor="name">Nome:</label>
          <input
            className="form-control"
            type="text"
            id="name"
            name="user_name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="email">Email:</label>
          <input
            className="form-control"
            type="email"
            id="email"
            name="user_email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="message">Messaggio:</label>
          <textarea
            className="form-control"
            name="message"
            id="message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            required
          ></textarea>
        </div>
        <button className="btn btn-info mt-2" type="submit">
          Invia
        </button>
      </form>
    </div>
  );
};

export default ContactForm;
